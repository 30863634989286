import "../../core/src/desktop/dialogs/doc-info/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/doc-info/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41S0WrbMBR9z1dcCoX0QUV2PK9TKewzBmUUxZJtgSwJ6cZJOvLvw4qc2tpW9hYd5557zrnn8a1ohnMISOHXBmDgJ3JUAnsG1RN1p+cJU2bGdnXCHBdCmY5BSd0J6ARxrTpDgtQtg4DcY5zlvlOGoHUMijIOXzaP884i7hQqOM3PDFotI3laVlB6Pz0nmAjlZYPKGgaN1YfBrIjKSNRagySod8lg5H5LCG9bZSSJeE/qh+f5T0epuh4Z1JSueHZ/F5Qr8PY4wR13DOoskPSePdS55+qWc59ENFw324LSsQcC5RTSw4qRQlllJF8iSdqRNqLnJrTWD+z6U3OUP7akinSL2Xo5Gy83C4kPZ4O6uuT7YPUB5Wr6a5L/cdYyVWJvvZCe7C2iHRjkkp/+L9r5uEshXmqOalwL+Rb5lqWLVLfmfVqGMMSIG6utz76PJUF5QhJkY43g/pwfo7ranUPbpfhXvaooXRfksnkVHDlx3jrp8UwaqzV3QYqXO/QHefcTMme3pIw1ufMwdnnhiyTjH5ZUY83K0pKP9XaU/sb6GYXzapgJvg9SKA7OK4NxMHPwhweAy+byG3YOHqVxBAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPropertyButton = '_1cmysst9';
export var container = '_1cmysst0';
export var hiddenInput = '_1cmysst6';
export var rowNameContainer = '_1cmysst3';
export var scrollBar = '_1cmysst5';
export var tableBodyRoot = '_1cmysst8';
export var timeRow = '_1cmysst7';
export var titleContainer = '_1cmysst1';
export var titleStyle = '_1cmysst2';
export var viewport = '_1cmysst4';